.heading {
    h3 {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        margin: 0;

        .reviewsStar {
            height: var(--font-size-h3);
            padding-inline-end: var(--padding-tiny);
        }

        .reviewsCount {
            padding-inline-start: var(--padding-tiny);
            font-weight: var(--font-weight-bolder);
            color: var(--text-muted);
        }
    }
}
