.errorAlert {
    max-width: unset;
    margin-block-end: 1em;
}

.spinnerOut {
    width: 3rem;
    margin: 5rem auto;
    display: block;
}
.spinnerIn {
    display: block;
    stroke: var(--brand-color);
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.fauxSummary {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--padding);
    align-items: center;
    padding: var(--padding);

    cursor: pointer;

    .title {
        display: flex;
        align-items: center;
        column-gap: var(--padding-small);
        flex-wrap: wrap;
        h3 {
            margin: 0;
        }
    }

    .expandIcons {
        svg {
            width: 1em;
        }
        .expandIcon {
            width: 1em;
        }
        .collapseIcon {
            width: 1em;
        }
    }
}

.fauxDetails {
    padding: var(--padding);
}

.hiddenDetails {
    display: none;
}

.card {
    margin-block-end: 1em;
}
