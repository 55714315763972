.endCard {
    padding: var(--padding);
    background-color: var(--bg-1);
    border-radius: var(--border-radius);
    display: grid;
    grid-template-columns: 1fr;
    margin-block-end: 20%;

    & p {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & a {
        display: flex;
        border: 1px solid var(--black);
        border-radius: var(--border-radius);
        padding: var(--padding-small) var(--padding);
        color: var(--text);
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: var(--brand-color);
            border: 1px solid var(--brand-color);
            color: var(--on-brand-color);
        }
    }
}

.courseCardWrapper {
    width: 100%;
    padding-block: var(--padding-smaller);
}
