.container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    min-width: min(100%, 600px);
    gap: var(--padding);
    margin: 0 auto;
    > * {
        width: 100%;
    }

    h2 {
        text-align: center;
        margin: 0;
    }
}

.coursesList {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: var(--padding);
}

.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--margin-giant) 0;
    gap: var(--padding);
    h3,
    p {
        margin: 0;
    }
}

.emptyStateIcon {
    padding: 1.5rem;
    background: var(--border);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--text);
    }
}
