.card {
    grid-area: educatorHeaderCard;
    display: flex;
    align-items: center;
    margin-block-end: 0.65rem;

    .educatorInfo {
        display: flex;
        gap: var(--margin-small);
        align-items: center;

        h4 {
            margin-block: 0;
            font-weight: var(--font-weight-bolder);
        }
    }
}

@media screen and (min-width: 568px) {
    .card {
        .educatorInfo {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }
    }
}
