.comboboxContainer {
    grid-auto-columns: 1fr;
    grid-auto-rows: 1fr;
    display: grid;
    position: relative;
}

.combobox {
    background-color: var(--bg-1);
    box-shadow: var(--card-box-shadow);
    border: 1px solid var(--border);
    border-radius: var(--border-radius);
    display: block;
    font-size: 1em;
    padding: var(--padding-small);
    text-align: left;
    cursor: pointer;
}

.combobox.open {
    border-radius: var(--border-radius) var(--border-radius) 0px 0px;
}

.combobox:focus {
    border-color: var(--webkit-focus-ring-color);
}

.listbox {
    background-color: var(--bg-1);
    box-shadow: var(--card-box-shadow);
    border: 1px solid var(--border);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    display: none;
    max-height: 300px;
    overflow-y: auto;
    left: 0;
    position: absolute;
    width: 100%;
    top: 46px;
    z-index: 100;
}

.listbox.open {
    display: block;
}

.option {
    padding: var(--padding-small);
    cursor: pointer;
}

.option:hover {
    background-color: rgb(0 0 0 / 10%);
}

.option.current {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: -1px;
}

.option[aria-selected='true'] {
    padding-inline-end: 30px;
    position: relative;
}

.option[aria-selected='true']::after {
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    content: '';
    height: 12px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(0, -50%) rotate(45deg);
    width: 6px;
}

.currentValue {
    position: relative;
}
.currentValue::before {
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    top: 0.15em;
    vertical-align: top;
    width: 0.45em;
    right: 0;
    transform: rotate(135deg);
    position: absolute;
}
.combobox.open {
    .currentValue::before {
        transform: rotate(315deg);
        top: 0.45em;
    }
}
