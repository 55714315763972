.container {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: var(--gap-list);
    margin-block: var(--margin-bigger);

    .expandButton {
        display: flex;
        align-items: center;
        gap: var(--gap-list-small);
        color: var(--text);
        width: 100%;
        font-weight: var(--font-weight-bolder);

        &:hover {
            color: var(--link-color);

            & svg {
                fill: var(--link-color);
            }
        }
    }

    form {
        margin-inline-start: var(--margin-bigger);
        width: calc(100% - var(--margin-bigger));
    }

    input {
        display: block;
        color: var(--text-alt);
        border: 1px solid var(--border);
        border-radius: var(--border-radius);
        height: 40px;
        padding: 0 var(--padding-small);
        &:focus {
            box-shadow:
                inset 0 0 2px rgb(67 90 111 / 14%),
                inset 0 0 0 1px #579ad9,
                0 0 0 3px rgb(16 112 202 / 14%);
            outline: none;
        }
        font-family: var(--font-family);
        font-size: var(--font-size);
    }
    .inputWithButton {
        display: flex;
        gap: var(--gap-list);
    }
}

.alert {
    margin: var(--margin) 0;
}
