.blockHeader {
    padding-block-end: var(--padding-small);
    border-block-end: 1px solid var(--border);

    h3 {
        font-weight: var(--font-weight-bold);
        font-size: var(--font-size);
        margin-block: var(--margin);
    }
}
