.subscriptionList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: var(--padding-small);
}

.breadcrumbs {
    margin-block-end: var(--margin);
}

.separator {
    margin-block-start: var(--margin-giant);
    margin-block-end: var(--margin-big);
}
