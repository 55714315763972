.heading {
    h2 {
        margin: 0;
        font-size: var(--font-size-bigger);
    }
}

.costSection {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: var(--font-size-smaller);
}

.totalSummary {
    font-size: var(--font-size-bigger);
    font-weight: var(--font-weight-bolder);
}
