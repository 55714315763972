.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    padding: var(--padding-smaller);
    margin-inline-start: var(--margin);
    font-size: var(--font-size-tiny);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;

    &.verified {
        background-color: var(--green);
        color: var(--white);
    }

    &.unverified {
        background-color: var(--lighter-blue);
        color: var(--grey);
    }
}
