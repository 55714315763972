.stepContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    text-align: start;
}

.fieldContainer {
    margin: var(--padding) 0;
    display: grid;
    position: relative;

    label {
        display: block;
        font-weight: var(--font-weight-bolder);
        margin: 0 0 var(--padding-tiny);
    }

    textarea {
        box-sizing: border-box;
        display: block;
        color: var(--text-alt);
        border: 1px solid var(--border);
        border-radius: var(--border-radius);
        height: 32px;
        font-family: var(--font-family);
        font-size: var(--font-size);
        width: 100%;
        max-width: 100%;
        min-height: 5em;
        resize: vertical;
        padding: var(--padding-small);

        &:focus {
            box-shadow:
                inset 0 0 2px rgb(67 90 111 / 14%),
                inset 0 0 0 1px #579ad9,
                0 0 0 3px rgb(16 112 202 / 14%);
            outline: none;
        }
    }

    textarea[data-error='true'] {
        box-shadow:
            inset 0 0 2px rgb(67 90 111 / 14%),
            inset 0 0 0 1px var(--red),
            0 0 0 3px rgb(16 112 202 / 14%);
    }
}

.description {
    font-size: 0.7em;
    color: var(--grey);
    margin-block-end: 1em;
}

.errorMessage {
    padding-inline-start: var(--padding-small);
    padding-block-start: var(--padding-small);
    color: var(--red);
    font-size: 0.7em;
}
