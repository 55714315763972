.tagline_container {
    font-weight: var(--font-weight-bolder);
    display: flex;
    align-items: center;
    gap: var(--gap-list-smaller);
}

.badge {
    display: inline-block;
    background-color: var(--item-bg-light);
    fill: #8f95b2;
    border-radius: var(--rounded-corners-pill);
    padding-block: 0.2em;
    padding-inline: 0.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & svg {
        margin-inline-end: var(--gap-list-smaller);
    }
}
