.image {
    position: relative;
    width: 100%;
    height: 100%;
    img {
        object-fit: contain;
        object-position: center;
    }
}

.blurred_background_image {
    width: 100%;
    height: 100%;
    display: block;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(10px) brightness(0.95);
    transform: scale(1.1);
    position: absolute;
    top: 0;
    z-index: -1;
}

.imageCarouselContainer {
    position: relative;
    .imageCount {
        background: var(--white);
        font-size: var(--font-size-smaller);
        font-weight: var(--font-weight-bolder);
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        padding-block: var(--padding-tiny);
        padding-inline: var(--padding-small);
        border-radius: var(--rounded-corners-pill);
        border: 1px solid var(--border-muted);
    }
}
