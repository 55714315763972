.review {
    border: 1px solid var(--border);
    border-radius: var(--border-radius);
    padding: var(--padding);
    padding-block-end: var(--padding-tiny);
    margin-block-end: var(--margin-bigger);

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: 1em;
    }

    .headerStart {
        display: flex;
        flex-direction: column;
        width: 75%;
    }

    .reviewedOn {
        margin-block-start: 0.3em;
    }

    @media screen and (max-width: 500px) {
        .headerStart {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }

    .ratingRow {
        border-block-start: 1px solid var(--border);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 1em;

        & > div {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .starWrapper {
            display: flex;
            align-items: center;
            width: 75px;
        }

        time {
            font-size: var(--font-size-smaller);
        }
    }
}
