.CademyRichtext_ForumPost_Indent {
    margin-inline-start: var(--padding);
}

.CademyRichtext_ForumPost_Indent > ul > li > p {
    margin: 0;
}

.CademyRichtext_ForumPost_Indent > ul {
    list-style-type: disc;
    padding-inline-start: var(--padding-small);
}
