.delivery_indicator {
    font-weight: var(--font-weight-bolder);
    display: flex;
    align-items: center;
    gap: var(--gap-list-smaller);
    flex-wrap: wrap;

    .badge {
        display: inline-block;
        background-color: var(--item-bg-light);
        border-radius: var(--rounded-corners-pill);
        padding-block: 0.2em;
        padding-inline: 0.6em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        & svg {
            margin-inline-end: var(--gap-list-smaller);
        }
    }

    .blue {
        background-color: var(--blue100);
        fill: var(--blue);
        color: var(--blue);
    }

    .green {
        background-color: var(--green100);
        fill: var(--dark-green);
        color: var(--dark-green);
    }

    .purple {
        background-color: var(--purple100);
        fill: var(--purple);
        color: var(--purple);
    }

    & span:last-of-type {
        & svg {
            flex: 0 0 12px;
        }
    }
}
