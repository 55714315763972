.dateStepSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    &Header {
        align-self: start;
    }
}

.spinner {
    stroke: var(--brand-color);
}

@media only screen and (max-width: 600px) {
    .dateStepSection {
        margin: var(--margin) 0;
    }
}
