.rating {
    display: flex;
    width: 100%;
}

.stars {
    display: flex;
    flex-direction: row;
    width: 100%;
}

button.star {
    all: unset;
    width: 100%;
    fill: var(--yellow);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        padding: 1px;
        width: 100%;
        height: 100%;
        path {
            stroke: var(--text);
            stroke-width: 5;
        }
    }
}

.stars .star.unchecked {
    fill: var(--bg-grey-dark);
}

.stars.interactive {
    cursor: pointer;
}

/**
 * Highlight all the stars on hover
 */
.stars.interactive:hover .star,
.stars.interactive:focus-within .star {
    fill: var(--yellow);
}

/**
 * Turn "off" all stars after the one being hovered.
 */
.stars.interactive:not(:hover) .star:focus ~ .star,
.stars.interactive .star:hover ~ .star {
    fill: var(--bg-grey-dark);
}
