.searchSection {
    margin-block-start: var(--padding);
    display: flex;
    gap: var(--padding-small);

    button {
        border-radius: var(--rounded-corners-pill);
        svg {
            margin-inline-end: var(--padding-small);
        }
        &:hover {
            border-color: var(--brand-color);
            color: var(--brand-color);
            fill: var(--brand-color);
            cursor: pointer;
        }
    }
    .searchInputContainer {
        position: relative;
        input {
            border-radius: var(--rounded-corners-pill);
            border: 1px solid var(--border);
            padding-inline-start: calc((var(--font-size) * 2) + var(--padding-small));
            padding-inline-end: var(--padding);
            height: 2.25rem;
        }
        > svg {
            position: absolute;
            align-self: center;
            top: 0.75em;
            left: 0.85em;
            width: 1em;
            height: 1em;
        }
    }
}

.filterModalFooterButtons {
    display: flex;
    justify-content: space-between;
}

.badge {
    position: relative;
    &:after {
        content: '';
        width: 0.8em;
        height: 0.8em;
        background: var(--red);
        border-radius: 50%;
        position: absolute;
        top: -0.4em;
        right: -0.4em;
    }
}
