.isLoading {
    filter: blur(2px);
}

.offersAndAddons,
.additionalCharges,
.discountAndVouchersSection {
    margin: 0;
    padding: 0;
    list-style: none;
}

.priceSummary {
    display: flex;
    gap: var(--gap-list);
    justify-content: space-between;
    margin: 0;
}

.heading {
    font-size: var(--font-size-bigger);
}

.container {
    font-size: var(--font-size-smaller);
}
