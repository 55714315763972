.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-block: 1.5em;
}

.icon {
    fill: var(--green);
    width: 20%;
    display: flex;
    justify-content: center;
}

.backTo {
    margin-block-start: 2em;
}
