.card {
    background-color: var(--brand-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: var(--rounded-corners-pronounced);

    .content {
        display: flex;
        flex-direction: column;
        color: var(--on-brand-color);

        .title {
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size);
        }

        .subtitle {
            font-size: var(--font-size-smaller);
            margin-block-start: var(--padding-smaller);
        }
    }

    .cta {
        a {
            color: var(--link-color);
        }

        .icon {
            width: 12px;
            height: 12px;
            fill: var(--link-color);
            margin-inline-start: var(--padding-tiny);
        }
    }
}
