.card {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .thumbnail {
        border-radius: var(--rounded-corners-pronounced);
        overflow: hidden;
        position: relative;
        width: 100%;
        aspect-ratio: 16/9;
        max-width: 150px;

        img {
            border-radius: 5px;
            object-fit: cover;
            object-position: center;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        padding-inline-end: var(--padding);
        flex-grow: 1;

        .title {
            font-size: var(--font-size-h3);
            margin: 0;
            font-weight: 600;
            margin-block-end: var(--padding-tiny);
        }

        .subtitle {
            color: var(--grey);
        }
    }

    .contents {
        display: flex;
        gap: var(--margin-small);
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .card {
        flex-direction: column-reverse;

        .details {
            .title {
                width: 100%;
            }
        }

        .thumbnail {
            width: 100%;
            max-width: 100%;
            margin-block-end: var(--padding);
        }
    }
}
