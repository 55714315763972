.container {
    position: relative;
}

.previousButton,
.nextButton {
    width: 2.25rem;
    height: 2.25rem;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid var(--text);
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: absolute;
    &:hover {
        background: var(--brand-color);
        color: var(--on-brand-color);
        fill: var(--on-brand-color);
        border-color: var(--on-brand-color);
    }
    &:disabled {
        background: rgba(255, 255, 255, 0.8);
        color: var(--text-muted);
        fill: var(--text-muted);
        border-color: var(--text-muted);
        cursor: not-allowed;
    }
}

.previousButton {
    grid-area: previous-button;
    justify-self: start;
    left: -1rem;
    top: 47%;
}

.nextButton {
    grid-area: next-button;
    justify-self: end;
    right: -1rem;
    top: 47%;
}

.scroller {
    grid-area: scroller;
    height: 100%;
    display: grid;
    grid-auto-columns: 100%;
    column-gap: 15px;
    grid-auto-flow: column;
    align-items: center;
    overflow-x: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    & > * {
        scroll-snap-align: center;
    }
    position: relative;
    border-radius: var(--border-radius);
}

.item {
    height: 100%;
    display: grid;
    grid-auto-flow: column;
    gap: var(--gap-list);
    max-width: 100%;
    overflow: hidden;
    grid-auto-columns: 1fr;
    justify-items: center;
}
