.container {
    gap: var(--padding);
    display: flex;
    flex-direction: column;
    margin-block-start: var(--section-gap-small);
}

.content {
    display: flex;
    flex-direction: row;
    flex: 1;

    gap: var(--padding-smaller);

    .column {
        display: flex;
        flex-direction: column;
        gap: var(--padding-small);
    }

    .primaryContent {
        flex-grow: 1;
    }

    .secondaryContent {
        width: 300px;
        max-width: 100%;
    }
}

@media screen and (max-width: 870px) {
    .content {
        flex-direction: column;
    }
}
