.noResults {
    text-align: center;
    margin-block: var(--padding);
}
.clearButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--padding-small);
}
