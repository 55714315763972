.stepContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.stars {
    padding-block-start: 2em;
    width: 60%;
}

@media screen and (max-width: 500px) {
    .stars {
        width: 80%;
    }
}
