.deleteButtonContainer {
    display: flex;
    align-items: center;

    height: 40px;
    @media screen and (max-width: 499px) {
        height: fit-content;
    }

    .confirmContainer {
        display: flex;
        gap: var(--margin);
        flex-direction: row;

        @media screen and (max-width: 499px) {
            flex-direction: column;
        }
    }

    .deleteButton {
        color: black;
        cursor: pointer;
        height: 20px;
        width: 20px;
        &:hover {
            fill: red;
        }
    }
}
