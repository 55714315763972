.card {
    color: var(--text);
    &:hover {
        box-shadow: var(--card-box-shadow-hover);
    }
}

.listContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .list {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 850px;

        .card {
            margin-block-end: var(--padding);
        }
    }
}

.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--margin-giant) 0;
    gap: var(--padding);
    h3,
    p {
        margin: 0;
    }
}

.emptyStateIcon {
    padding: 1.5rem;
    background: var(--border);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: var(--text);
    }
}
