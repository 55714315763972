.nav {
    display: flex;
    width: 100%;
    flex-direction: row;
    border-block-start: 1px solid var(--bg-grey-dark);
    margin-block-start: 2em;
    padding-block-start: var(--padding);
    user-select: none;
}

.buttonGroup {
    flex: 1;
}

.primaryButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.nextButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.nextButton svg {
    height: 50%;
    margin-inline-end: 0.5em;
}
