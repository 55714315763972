.occurrenceSection {
    grid-area: courseOccurrences;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block-end: var(--gap-list);
}

.occurrenceSectionHeader {
    align-self: start;
    margin-block-end: 0;
}
