.popoverContent {
    min-width: 150px;
    max-width: 320px;
    background-color: var(--white);
    border-radius: var(--border-radius);
    border: 1px solid var(--border);
    box-shadow: var(--card-box-shadow);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
    padding: 1em;
    margin: var(--margin);
    z-index: 1005;
    display: flex;
    flex-direction: column;
    gap: var(--gap-list-small);

    &TextArea {
        padding: var(--padding-smaller);
        border: 1px solid var(--border);
        border-radius: var(--border-radius);
        resize: none;
    }

    &Button {
        align-self: flex-end;
    }
}

.errorAlert {
    font-size: var(--font-size-extra-small);
    margin: 0;
}

.popoverContent[data-side='top'] {
    animation-name: slideDownAndFade;
}

.popoverContent[data-side='right'] {
    animation-name: slideLeftAndFade;
}

.popoverContent[data-side='bottom'] {
    animation-name: slideUpAndFade;
}

.popoverContent[data-side='left'] {
    animation-name: slideRightAndFade;
}

@keyframes slideUpAndFade {
    from {
        opacity: 0;
        transform: translateY(2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideRightAndFade {
    from {
        opacity: 0;
        transform: translateX(-2px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideDownAndFade {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideLeftAndFade {
    from {
        opacity: 0;
        transform: translateX(2px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}
