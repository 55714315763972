.container {
    display: flex;
    flex-direction: column;
    gap: var(--padding);
}

.contents {
    display: flex;
    flex-direction: column;
    margin-block-start: var(--padding-small);
    margin-block-end: 0;

    & p {
        margin: 0;
    }
}
