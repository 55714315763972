.ImageAttachment {
    position: relative;
    &Image {
        border-radius: var(--rounded-corners-standard);
    }

    &Button {
        cursor: pointer;
        position: absolute;
        bottom: 1em;
        left: 0;
        height: 2rem;
        width: 2.5rem;
        margin-left: var(--margin);
        font-size: var(--font-size-extra-small);
        opacity: 0.75;
        transition: opacity 0.3s ease;
        z-index: 1002;

        &:hover {
            opacity: 1;
        }
    }
}
