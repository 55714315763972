.heading {
    margin-block-end: 2em;
}

.questions {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: left;
}

.question {
    box-sizing: border-box;
    width: 50%;
    padding-inline: 5%;
    margin-block-end: 2em;
}

@media screen and (max-width: 400px) {
    .question {
        width: 75%;
        padding: 0;
    }
}

.label {
    margin-block-end: 0.5em;
}
