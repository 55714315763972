.summaryCard {
    max-height: max-content;

    .highlights {
        margin-block-end: var(--padding);

        & > span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 0.5em;
            margin-block-end: 0.5em;

            & > span {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }

            & > span:last-of-type {
                font-size: 0.9em;
                font-weight: 500;
            }
        }
    }

    .summaryItems {
        .summarySection {
            border-top: 1px solid var(--border);
            margin-block-start: 1em;
            padding-block-start: 1em;

            &:first-of-type {
                margin-block-start: unset;
                border-top: none;
            }

            & > span {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 0.5em;
                margin-block-end: 0.5em;

                font-weight: 500;
            }

            &:last-of-type {
                & > span {
                    font-weight: 900;
                }
            }
        }
    }
}

.copyableText {
    user-select: all;
}
