.container {
    margin-block-start: var(--padding);
}

.sections {
    display: none;
}

.heading {
    h2 {
        font-size: var(--font-size-bigger);
        margin-block-start: 0;
        margin-block-end: var(--margin);
    }

    p {
        margin-block-end: 0;
    }

    a {
        color: var(--text);

        &:hover {
            color: var(--link-color);
        }
    }
}

.subheading {
    display: none;
}
.taglineDetail {
    border-bottom: 1px solid var(--border);
}
.mobileFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block-start: var(--margin-big);

    button {
        font-weight: var(--font-weight-bolder);
    }
}
.mobileTotalCost {
    font-size: var(--font-size-bigger);
    font-weight: var(--font-weight-bold);
}

.expanded {
    .sections {
        display: inherit;
        > * {
            border-bottom: 1px solid var(--border);
            padding-block: var(--margin);
        }
        > :last-of-type {
            border-bottom: none;
            padding-block-end: 0;
        }
    }
    .subheading {
        display: inherit;
    }
    .taglineDetail {
        display: none;
    }
    .mobileTotalCost {
        visibility: hidden;
    }
}

@media screen and (min-width: 768px) {
    .sections {
        display: inherit;
        > * {
            border-bottom: 1px solid var(--border);
            padding-block: var(--margin);
        }
        > :last-of-type {
            border-bottom: none;
            padding-block-start: var(--padding);
            padding-block-end: 0;
        }
    }
    .subheading {
        display: inherit;
    }
    .taglineDetail {
        display: none;
    }
    .mobileFooter {
        display: none;
    }
}
