.container {
    display: flex;
    flex-direction: column;
    gap: var(--margin-small);
}

.footerButtons {
    display: flex;
    justify-content: space-between;
}
