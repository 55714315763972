.container {
    margin-block-end: var(--margin);
    padding: var(--padding-small) var(--padding) !important;

    .headerBar {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr max-content max-content;
        align-items: center;
        gap: var(--margin-giant);
        font-size: var(--font-size);

        .name {
            font-weight: var(--font-weight-bolder);
        }
        .controls {
            display: flex;
            align-items: center;
            gap: var(--gap-list-small);

            .button {
                border-radius: 50%;
                height: 2em;
                width: 2em;
                padding: 0px;
                cursor: pointer;
                &:disabled {
                    cursor: not-allowed;
                }

                svg {
                    width: var(--font-size);
                    height: var(--font-size);
                }
            }
        }
    }

    .description {
        color: var(--text-muted);
        font-size: var(--font-size-smaller);
        margin: 0;
    }

    .saleDate {
        color: var(--orange);
        font-size: var(--font-size-smaller);
        font-weight: var(--font-weight-bolder);
        margin: 0;
    }
}

@media screen and (max-width: 420px) {
    .container {
        .headerBar {
            gap: var(--margin);
        }
    }
}
