.spinner {
    height: 1.2em;
}
.spinnerInner {
    stroke: var(--brand-color);
}

.loadNewPosts {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    button {
        color: var(--text-muted);
        font-size: 0.8em;
        padding: var(--padding-tiny);
        height: 1.25em;

        &:hover {
            color: var(--blue);
            background-color: transparent;
        }
    }
}
