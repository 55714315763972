.educatorResponse {
    border-inline-start: 1px solid var(--border);
    padding-inline-start: var(--padding);

    .header {
        display: grid;
        grid-template-columns: 50px 1fr;
        grid-template-areas:
            'image name'
            'image date';

        img {
            grid-area: image;
            width: 40px;
            height: 40px;
            border-radius: var(--border-radius);
            object-fit: cover;
        }

        .educatorName {
            grid-area: name;
            font-weight: var(--font-weight-bold);
            font-size: var(--font-size-smaller);
            margin-block-end: var(--margin-tiny);
        }

        .date {
            grid-area: date;
            font-size: var(--font-size-tiny);
            font-weight: unset;
            color: var(--grey);
        }
    }

    & > p {
        padding-inline-start: var(--padding-tiny);
    }
}
