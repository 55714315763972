.AttachmentList {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: var(--gap-list);
    padding: var(--padding-small);
    margin: 0;
    background: var(--bg-2);
    border: 1px solid var(--border);
    border-radius: var(--rounded-corners-standard);

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        padding: var(--padding-small) 0;
    }
}
