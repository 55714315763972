.attendeesCard {
    display: flex;
    flex-direction: column;
    gap: var(--margin);
    .attendeeRow {
        display: grid;
        align-items: center;
        grid-template-columns: max-content 1fr min-content;

        padding: var(--padding-smaller);

        gap: var(--padding-smaller);

        .attendeeName {
            font-weight: var(--font-weight-bold);
        }

        .attendeeEmail {
            color: var(--text-muted);
        }

        border: solid 1px var(--border);
        border-radius: var(--border-radius);
        margin-block: var(--margin-small);

        .avatar {
            width: 50px;
            height: 50px;
        }

        .attendeeDetails {
            display: flex;
            flex-wrap: wrap;
            column-gap: var(--padding-smaller);
            min-width: 0;
        }
    }
}
