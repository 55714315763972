.modal {
    padding-block: 1em;
    padding-inline: 2em;
    display: flex;
    flex-direction: column;
}

.loadMoreButton {
    display: flex;
    justify-content: center;
    margin-block-start: var(--margin-large);
    margin-block-end: var(--margin-large);
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-block-end: 1em;

    .close {
        height: 2em;
        border: none;

        svg {
            fill: var(--grey);
            height: 100%;
        }

        &:hover {
            cursor: pointer;
            svg {
                fill: var(--link-color);
            }
        }
    }
}
