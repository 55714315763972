.AttachmentListItem {
    padding: var(--padding-small);
    border-radius: var(--rounded-corners-pronounced);
    min-height: 3.5rem;
    border: 1px solid var(--border);
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    gap: var(--gap-list-small);
    transition: all 0.2s ease;
    background: var(--white);
    box-shadow: var(--card-box-shadow);

    &:hover {
        box-shadow: var(--card-box-shadow-hover);
        transform: translateY(-1px);
    }

    &Content {
        display: grid;
        grid-template-columns: min-content 1fr;
        align-items: center;
        gap: var(--gap-list-small);
    }

    &Preview {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5rem;
        width: 2.5rem;

        svg {
            width: 2em;
            height: 2em;
        }
    }

    &Info {
        display: grid;
        grid-template-rows: min-content min-content;
        gap: var(--gap-list-smaller);
        min-width: 0; // Enables text truncation
    }

    &FileName {
        margin: 0;
        font-weight: var(--font-weight-bolder);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--text);
        font-size: var(--font-size-smaller);
    }

    &MimeType {
        margin: 0;
        color: var(--text-muted);
        font-size: var(--font-size-extra-small);
        text-transform: uppercase;
    }

    &Media {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: var(--border-radius);
        object-fit: cover;
    }

    &Processing {
        pointer-events: none;
        opacity: 0.7;
        background: var(--bg-2);

        > div,
        button {
            opacity: 0.5;
        }
    }

    &Button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: var(--margin);
        border: 1px solid transparent;
        padding: 0.5rem;
        fill: var(--text-muted);
        background-color: transparent;
        border-radius: 50%;
        transition: all 0.2s ease;
        height: 2rem;
        width: 2rem;

        &:hover {
            fill: var(--blue);
            background-color: var(--bg-2);
        }
    }
}

.AttachmentListItemProgress {
    width: 100%;
    height: 4px;
    margin-top: var(--margin-small);
    border-radius: 2px;
    overflow: hidden;

    &::-webkit-progress-bar {
        background-color: var(--bg-2);
    }

    &::-webkit-progress-value {
        background-color: var(--brand-color);
        transition: width 0.3s ease;
    }

    &::-moz-progress-bar {
        background-color: var(--brand-color);
    }
}
