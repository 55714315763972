.settingsCard {
    color: var(--text);
    max-width: 350px;

    .settingsCardHeader {
        display: flex;
        align-items: center;
        gap: 1em;

        svg {
            display: inline;
            width: 2em;
            height: 2em;
        }

        h3 {
            display: inline;
            margin: 0;
        }
    }

    p {
        margin-block-start: 1em;
        margin-block-end: 0em;
    }
}
