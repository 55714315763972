.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip {
    padding: var(--padding-small);
    border: 1px solid var(--border);
    background: var(--white);
    border-radius: var(--border-radius);
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--padding-smaller);
    align-items: center;
    overflow-wrap: anywhere;
    max-width: 90vw;
}

.copyIcon {
    width: 1rem;
    height: 1rem;
}
