.courseCard {
    height: 100%;
    border-radius: var(--border-radius);
    box-shadow: var(--card-box-shadow);
    border: 1px solid var(--border-muted);
    display: grid;
    grid-template-rows: auto 1fr;
}

.cardCoverImage {
    position: relative;
    height: 150px;
    img {
        border-radius: 6px 6px 0 0;
        object-fit: cover;
        object-position: center;
        box-shadow: var(--card-box-shadow);
    }
}

.cardContent {
    padding: var(--padding-small);
    display: grid;
    grid-template-rows: auto auto 1fr;
    gap: 5px;

    h3 {
        margin-block-start: var(--margin-small);
        margin-block-end: var(--margin);
        font-size: var(--font-size);
        font-weight: var(--font-weight-bold);
    }
}

.cardDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;

    span,
    div {
        font-size: 0.9rem;
    }
}

.cardFooter {
    align-self: end;
    display: flex;
    justify-content: space-between;

    .cardPrice {
        display: flex;
        font-weight: var(--font-weight-bold);
        margin: 0;
        gap: var(--gap-list-smaller);
        align-items: center;
    }
}

.courseCardLink {
    width: 100%;
    color: var(--text);
    height: 100%;
    &:hover {
        h3 {
            color: var(--blue-hover);
        }
        img {
            opacity: 0.9;
            transition: all 0.3s;
        }
    }
}

.saveCourseOverlay {
    height: 100%;
}
