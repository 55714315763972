.alert {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: var(--padding-tiny);
    padding-inline: var(--padding-small);
    margin-block: 20px;

    p {
        margin: 0;
        font-weight: var(--font-weight-bolder);
        font-size: var(--font-size-smaller);
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--cademy-brand-color);
        border: 1px solid transparent;

        &:hover {
            border-color: var(--cademy-brand-color);
        }

        svg {
            width: 0.8em;
            display: inline-block;
            margin-inline-start: 0.5em;
            fill: var(--cademy-brand-color);
        }
    }
}
