.course_article {
    > header {
        grid-area: header;
        > h1 {
            margin-block-start: 0;
            margin-block-end: 0.5rem;
        }
    }

    h2 {
        font-size: 1.25em;
    }
}

.carousels_wrapper {
    h2 {
        font-size: 1.25em;
        margin-block-start: var(--margin-bigger);
    }
}

.course_image {
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
    border: 1px solid var(--border-muted);

    img {
        object-fit: contain;
        object-position: center;
        border-radius: var(--border-radius);
        //below is needed to make rounded corners work
        position: relative !important;
        aspect-ratio: 16 / 9;
        display: block;
    }

    .blurred_background_image {
        width: 100%;
        height: 100%;
        display: block;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        filter: blur(10px) brightness(0.95);
        transform: scale(1.1);
        position: absolute;
        aspect-ratio: 16 / 9;
        top: 0;
        z-index: -1;
    }
}

.unsplash_notice {
    font-size: 10pt;
    display: block;
    line-height: 10pt;
    text-align: center;
    width: 80%;
    margin: var(--padding-tiny) auto;
}

.faq_section {
    grid-area: courseFaqs;
    margin-block-end: var(--gap-list);

    ul {
        margin: 0;
        padding: 0;
    }
    li {
        list-style-type: none;
    }
    details {
        padding-block-end: 0.75rem;
        margin-block-end: 0.75rem;

        .faqIconMinus {
            display: none;
        }

        &[open] {
            .faqIconPlus {
                display: none;
            }
            .faqIconMinus {
                display: block;
            }
        }

        summary {
            font-weight: var(--font-weight-bolder);
            line-height: 1.5em;
            list-style: none;
            display: flex;
            align-items: center;
            gap: 0.5em;
            &::-webkit-details-marker {
                display: none;
            }

            svg {
                flex: 0 0 1rem;
                height: 1rem;
                width: 1rem;
                fill: var(--icon-fill-light);
            }

            &:hover {
                cursor: pointer;
                color: var(--link-color);

                svg {
                    fill: var(--link-color);
                }
            }
        }
    }
}

.educator_section {
    grid-area: educator;
    margin-block-end: var(--gap-list);

    .educator_section_inner {
        background-color: var(--bg-3);
        border: 1px solid var(--border-muted);
        border-radius: var(--border-radius);
        padding: var(--padding);
    }

    .mainInfo {
        display: flex;
        gap: var(--margin);
        align-items: center;

        .locationAndRatingContainer {
            display: flex;
            align-items: center;
            gap: var(--gap-list-small);
        }
    }

    .educator_logo {
        background-color: var(--white);
        border: 1px solid var(--border-muted);
        border-radius: var(--border-radius);
        position: relative;
        cursor: pointer;

        img {
            object-fit: contain;
            margin: 0;
            max-width: 150px;
        }
    }

    .educator_name_link {
        color: var(--text);
        font-size: 1rem;
        font-weight: var(--font-weight-bolder);
        display: block;
        &:hover {
            color: var(--link-color);
        }
    }
}

.location_section {
    grid-area: courseLocation;
    margin-block-end: var(--gap-list);

    a {
        display: flex;
        align-items: center;
        margin-block-end: var(--margin-big);
        span {
            margin-inline-start: var(--padding-tiny);
        }
    }
}

.course_image_section {
    grid-area: courseImages;
    margin-block-end: var(--gap-list);

    span {
        max-width: 100%;
        padding-block-start: var(--padding-tiny);
    }
}

.description_section {
    grid-area: courseDescription;
    overflow: auto;
    margin-block-end: var(--gap-list);
    ul,
    ol {
        font-size: var(--font-size);
        line-height: var(--font-size-h2);
        letter-spacing: -0.05px;
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }
    td {
        padding: var(--padding-tiny);
        border: 1px solid var(--border);
        vertical-align: top;
    }

    pre {
        display: grid;
        grid-template-columns: 1fr;
        code {
            border: 1px solid var(--border-muted);
            background-color: var(--bg-2);
            padding: var(--padding);
            border-radius: var(--border-radius);
            font-family: monospace;
            overflow-x: scroll;
        }
    }

    code {
        display: inline-block;
        background-color: var(--bg-2);
        color: var(--red);
        padding: var(--padding-tiny);
        border-radius: var(--border-radius);
    }

    blockquote {
        display: block;
        border-left: 4px solid var(--border);
        padding: var(--padding);
        margin-inline-start: var(--margin-bigger);
        margin-block-start: var(--margin-small);
        margin-block-end: var(--margin);
    }

    details {
        margin-inline-start: var(--margin-bigger);
        summary {
            margin-inline-start: calc(var(--margin-bigger) * -1);
        }
    }

    hr {
        border: 1px solid var(--border);
    }

    div[data-style-as='h1'] {
        font-size: var(--font-size-h1);
        font-weight: var(--font-weight-bold);
        margin-block: var(--margin);
    }
    div[data-style-as='h2'] {
        font-size: var(--font-size-h2);
        font-weight: var(--font-weight-bold);
        margin-block: var(--margin);
    }
    div[data-style-as='h3'] {
        font-size: var(--font-size-h3);
        font-weight: var(--font-weight-bold);
        margin-block: var(--margin);
    }

    .image {
        display: flex;
        width: 100%;
        justify-content: space-around;
    }

    iframe {
        border: 0;
    }
}

.course_tags_section {
    grid-area: courseTags;
    margin-block-end: var(--gap-list);

    .pill_list {
        list-style-type: none;
        display: flex;
        flex-direction: row;
        gap: var(--gap-list-small);
        flex-wrap: wrap;
        padding: 0;

        &_item {
            font-size: var(--font-size-smaller);
            padding-block: var(--padding-smaller);
            padding-inline: var(--padding);
            border: 1px var(--border-muted) solid;
            background-color: var(--bg-3);
            border-radius: var(--rounded-corners-pill);
            cursor: pointer;

            a {
                color: var(--text);
            }

            &:hover {
                color: var(--link-color);
                background-color: var(--item-bg-light);

                a {
                    color: var(--link-color);
                }
            }
        }
    }
}

.courseReviewsSection {
    grid-area: courseReviews;
    margin-block-end: var(--gap-list);

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .minimal {
            margin: 0;
        }
    }
    .ratingSummary {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .showAllContainer {
        display: flex;
        justify-content: center;
    }
}

.reviewsPlaceholder {
    .alert {
        text-align: center;
        margin-block-end: 0.5em;
        margin-inline: auto;
        text-wrap: balance;

        span {
            display: inline-block;
        }
    }
}

.course_article {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1.25fr;
    column-gap: 5em;
    grid-template-areas:
        'header header bookAside'
        'educatorHeaderCard educatorHeaderCard bookAside'
        'courseImages courseImages bookAside'
        'courseHighlights courseHighlights bookAside'
        'courseOccurrences courseOccurrences bookAside'
        'courseDescription courseDescription bookAside'
        'courseContents courseContents bookAside'
        'courseFaqs courseFaqs bookAside'
        'courseLocation courseLocation bookAside'
        'educator educator bookAside'
        'courseTags courseTags bookAside'
        'courseReviews courseReviews bookAside';
}

.course_image_section {
    .imageCarouselContainer {
        width: 100%;
        height: 100%;
        aspect-ratio: 16 / 9;
    }
}

.occurrencePicker {
    border-radius: var(--border-radius);
    box-shadow:
        0 0 1px rgb(67 90 111 / 30%),
        0 2px 4px -2px rgb(67 90 111 / 47%);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--padding-small) 0;
}

.contents_section {
    grid-area: courseContents;
    margin-block-end: var(--gap-list);

    .section_heading {
        text-transform: capitalize;
    }

    .course_summary {
        display: flex;
        gap: 0.5rem;
        color: var(--text-muted);
        font-size: 0.9rem;
        margin-block-end: 1rem;
    }

    .sections_list {
        border: 1px solid var(--border-muted);
        border-radius: var(--border-radius);
        overflow: hidden;
    }

    .section_details {
        border-bottom: 1px solid var(--border-muted);
        background: var(--bg-2);

        &:last-child {
            border-bottom: none;
        }

        .faqIconMinus {
            display: none;
        }

        &[open] {
            .faqIconPlus {
                display: none;
            }
            .faqIconMinus {
                display: block;
            }

            summary {
                background: var(--bg-3);
            }
        }

        summary {
            padding: 1rem;
            font-weight: var(--font-weight-bolder);
            list-style: none;
            display: flex;
            align-items: center;
            gap: 0.75rem;
            cursor: pointer;
            transition: background-color 0.2s ease;

            &::-webkit-details-marker {
                display: none;
            }

            &:hover {
                background: var(--bg-3);
                color: var(--link-color);
            }

            svg {
                flex: 0 0 1rem;
                height: 1rem;
                width: 1rem;
                fill: var(--icon-fill-light);
            }

            .section_header {
                flex: 1;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
            }

            .lesson_count {
                font-size: 0.9rem;
                color: var(--text-muted);
                font-weight: normal;
            }
        }

        .lessons_list {
            margin: 0;
            padding: 0.5rem 0;
            list-style: none;
            background: var(--bg-1);
        }

        .lesson_item {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: var(--padding-small);
            padding-inline-start: 3.25rem;
            transition: background-color 0.2s ease;

            &:hover {
                background: var(--bg-2);
            }

            .lesson_number {
                color: var(--text-muted);
                font-size: 0.9rem;
                min-width: 1.5rem;
            }

            .lesson_name {
                flex: 1;
            }
        }
    }
}

.secondary_buttons {
    svg {
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 650px) {
    .course_article {
        grid-template-columns: 1fr;
        grid-template-areas:
            'header'
            'educatorHeaderCard'
            'courseImages'
            'bookAside'
            'courseHighlights'
            'courseOccurrences'
            'courseDescription'
            'courseContents'
            'courseFaqs'
            'courseLocation'
            'educator'
            'courseTags'
            'courseReviews';
    }
}

@media only screen and (orientation: portrait) and (min-width: 651px) and (max-width: 950px) {
    .course_article {
        grid-template-columns: 1fr 0.75fr;
        grid-template-areas:
            'header header'
            'educatorHeaderCard educatorHeaderCard'
            'courseImages courseImages'
            'courseHighlights bookAside'
            'courseOccurrences bookAside'
            'courseDescription bookAside'
            'courseContents bookAside'
            'courseFaqs bookAside'
            'courseLocation bookAside'
            'educator bookAside'
            'courseTags bookAside'
            'courseReviews bookAside';
        column-gap: 2vmin;
    }

    .courseReviewsSection {
        .reviewsBreakdown {
            grid-template-columns: 1fr;
        }
    }
}

.controlBar {
    flex-wrap: wrap;
    justify-content: end;
}
