.attachments {
    --maxAttachmentLayoutHeight: 90vh;

    width: 100%;

    .attachment {
        width: 100%;
        height: 100%;
        max-width: 100%;
        cursor: pointer;
    }

    .singularLayout {
        .attachment,
        .videoAttachmentProcessing img {
            max-height: var(--maxAttachmentLayoutHeight);
            height: auto;
            object-fit: cover;
        }

        .videoAttachmentProcessing img {
            object-fit: contain;
        }
    }

    .dualLayout {
        display: flex;
        flex-direction: row;
        gap: 0.5em;
        align-items: stretch;
        max-height: var(--maxAttachmentLayoutHeight);

        .attachmentContainer {
            min-width: 25%;
        }

        .attachment {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .scrollableLayout {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        gap: 0.5em;
        align-items: stretch;
        overscroll-behavior-x: contain;
        max-height: var(--maxAttachmentLayoutHeight);

        .attachmentContainer {
            flex: 0 0 auto;
            max-width: 45%;
            width: 100%;
            overflow: hidden;
            scroll-snap-align: start;
            position: relative;
        }

        .attachment {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .scrollableLayout::-webkit-scrollbar {
        display: none; /* Hide scrollbar in Webkit browsers */
    }

    .scrollableLayout {
        -ms-overflow-style: none; /* Hide scrollbar in IE/Edge */
        scrollbar-width: none; /* Hide scrollbar in Firefox */
    }
}
